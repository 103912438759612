<template>
  <Layout>
    <div>
      <GmapMap :center="center" :options="options" :zoom="10" id="google-map-div" map-style-id="roadmap"
        style="width: 100%; height: 85vh" ref="mapRef">
        <GmapMarker v-if="driver" icon="https://b-seen-storage.s3.amazonaws.com/p_7GiBBhSppxKBymvPtYfg.png"
          :position="driver" @click="toggleInfoWindow(loc)" />
        <GmapMarker icon="https://b-seen-storage.s3.amazonaws.com/p_7GiBBhSppxKBymvPtYfg.png"
          v-for="loc, index in locations" :key="index" :position="loc" @click="toggleInfoWindow(loc)" />
      </GmapMap>
    </div>
  </Layout>
</template>
<style>
.vehicleinfo {
  cursor: pointer;
}
</style>
<script>
import Layout from "../../layouts/main";
import { terminals } from "@/config/api/terminals";

export default {
  components: {
    Layout,
  },
  data() {
    return {
      options: {
        backgroundColor: '#000',
        styles: [
          {
            featureType: 'all',
            elementType: 'geometry.fill',
            stylers: [
              {
                weight: '2.00',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#9c9c9c',
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f2f2f2',
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'landscape.man_made',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: -100,
              },
              {
                lightness: 45,
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#7b7b7b',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#46bcec',
              },
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#c8d7d4',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#070707',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
        ],
      },
      center: { lat: 24.7136, lng: 46.6753 },
      driver: '',
      driver_id: '',
      locations: [],
      campaign_id: '',
      isBusy: false,
      selectedInspection: undefined,
      timeoutID: undefined
    };
  },
  mounted() {
    if (this.customHeight != null) {
      document.getElementById("google-map-div").style.height =
        this.customHeight + "px";
    }
    this.driver_id = this.$route.query.driver_id
    this.campaign_id = this.$route.query.campaign_id
    this.loadDriverData()
    this.loadCampaignData()

  },
  methods: {
    toggleInfoWindow(marker) {
      console.log(marker)
      if (marker.user)
        this.$router.push({ name: "DriverDetails", params: { id: marker.user } });
    },
    updateMilageModal() {
      this.$bvModal.show("inspection-modal");
    },
    hideActionModal() {
      this.$bvModal.hide("inspection-modal");
    },
    selectedInspectionSelected(data) {
      this.$router.push({ name: "InspectionDetails", params: { id: data._id } });
    },
    loadDriverData() {
      if (!this.driver_id)
        return;
      let api = terminals.lastLocation;
      api.params = {
        user: this.driver_id,
        source: "Tms"
      }
      this.generateAPI(api)
        .then((res) => {

          const locationData = res.data.last_location;
          if (locationData && locationData.length) {
            this.driver = { lat: locationData[0].location_LngLat.coordinates[1], lng: locationData[0].location_LngLat.coordinates[0] }
            this.$refs.mapRef.panTo(this.driver);
          }
          this.timeoutID = setTimeout(this.loadDriverData, 5000);

        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadCampaignData() {
      if (!this.campaign_id)
        return;
      let api = terminals.last_location_by_campaign;
      api.id = this.campaign_id

      this.generateAPI(api)
        .then((res) => {

          const locationData = res.data.ads;
          if (locationData && locationData.length) {
            this.locations = locationData.map((loc) => {
              return { lat: loc.lastLocation.coordinates[1], lng: loc.lastLocation.coordinates[0], user: loc.driver_id }
            })
          }
          this.timeoutID = setTimeout(this.loadCampaignData, 5000);

        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  watch: {

  },
  beforeDestroy() {
    if (typeof this.timeoutID === 'number') {

      clearTimeout(this.timeoutID);
    }
    this.timeoutID = undefined;
  }
};
</script>

<style>
.spaceDetails {
  justify-content: space-between;
}
</style>